import React, { useEffect } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadPolygonMaskPlugin } from "@tsparticles/plugin-polygon-mask";
import { loadSlim } from "@tsparticles/slim";
import { loadSnowPreset } from "@tsparticles/preset-snow";

/**
 * The properties for {@link SnowForeground}
 */
export type SnowForegroundProps = {};

/**
 * Snow foreground
 */
export default function SnowForeground(props: SnowForegroundProps) {
    const [init, setInit] = React.useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSnowPreset(engine);
            await loadSlim(engine);
        }).then(() => setInit(true));
    }, []);

    return (
        init && (
            <Particles
                className={"fixed left-0 top-0 z-10 h-screen w-full"}
                options={{
                    particles: {
                        shape: {
                            type: "square", // starting from v2, this require the square shape script
                        },
                    },
                    preset: "snow",
                }}
            />
        )
    );
}
