import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import StepBar from "../components/base/step-bar";
import Pane from "../components/base/pane";
import { Subheading, Heading } from "../components/base/heading";

const day1 = [
    {
        label: "Start",
        start: new Date("2024-12-05 09:00"),
        end: new Date("2024-12-05 09:00"),
        description: "",
        audience: "",
    },
    {
        label: "Pizza Workshop (Niklas)",
        start: new Date("2024-12-05 10:00"),
        end: new Date("2024-12-05 11:00"),
        description: "How To Pizza",
        audience: "",
    },
    {
        label: "Essen",
        start: new Date("2024-12-05 13:00"),
        end: new Date("2024-12-05 13:00"),
        description: "Chili con/sin carne",
        audience: "",
    },
    {
        label: "Leichter Organisiert im Arbeitsalltag (PM)",
        start: new Date("2024-12-05 14:30"),
        end: new Date("2024-12-05 15:15"),
        description: "",
        audience: "Service / Tech",
    },
    {
        label: "Tag 1 Ende",
        start: new Date("2024-12-05 18:00"),
        end: new Date("2024-12-05 18:00"),
        description: "",
        audience: "",
    },
];

const day2 = [
    {
        label: "Start",
        start: new Date("2024-12-06 09:00"),
        end: new Date("2024-12-06 09:00"),
        description: "",
        audience: "",
    },
    {
        label: "Essen",
        start: new Date("2024-12-06 12:00"),
        end: new Date("2024-12-06 12:00"),
        description: "Karottensuppe",
        audience: "",
    },
    {
        label: "Angriffe auf Crypto (Chris & Olli)",
        start: new Date("2024-12-06 14:00"),
        end: new Date("2024-12-06 15:00"),
        description: "Danach gibt es einen kleinen CTF",
        audience: "Tech",
    },
    {
        label: "Weihnachtsfeier Start",
        start: new Date("2024-12-06 16:00"),
        end: new Date("2024-12-06 16:00"),
        description: "",
        audience: "",
    },
    {
        label: "Essen",
        start: new Date("2024-12-06 17:30"),
        end: new Date("2024-12-06 17:30"),
        description: "Pizza",
        audience: "",
    },
    {
        label: "Mario Kart / Just Dance (by Ally + Maya)",
        start: new Date("2024-12-06 19:30"),
        end: new Date("2024-12-06 19:30"),
        description: "",
        audience: "",
    },
];

/**
 * The properties for {@link Index}
 */
export type IndexProps = {};

/**
 * The index site
 */
export default function Index(props: IndexProps) {
    return (
        <div className={"my-6 flex w-full items-center justify-center p-2 sm:my-12"}>
            <div className={"flex flex-col gap-6"}>
                <Pane className={"justify-center"}>
                    <Heading>Truffledays - Dezember</Heading>
                </Pane>
                <Pane>
                    <div className={"grid w-full gap-12 sm:gap-24 md:grid-cols-2"}>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 1</Subheading>
                            <StepBar
                                steps={day1.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    audience: s.audience,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 2</Subheading>
                            <StepBar
                                steps={day2.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    audience: s.audience,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                    </div>
                </Pane>
            </div>
        </div>
    );
}

export const Route = createFileRoute("/")({
    component: Index,
});
